<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.plat.sys.feedback.title.editHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        v-loading="formLoading"
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {submit, getDetail} from '@/api/system/feedback';
import {mapGetters} from "vuex";

export default {
  name: "protocolEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
      formLoading: false
    }
  },
  computed: {
    ...mapGetters([
      "userInfo"
    ]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtnn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.sys.feedback.field.handler'),
          prop: 'handler',
          labelWidth: 130,
          readonly: true,
          span: 8,
        },
        {
          label: this.$t('cip.plat.sys.feedback.field.submitter'),
          prop: 'submitter',
          labelWidth: 130,
          readonly: true,
          span: 8,
        },
        {
          label: this.$t('cip.plat.sys.feedback.field.disposeResult'),
          prop: 'disposeResult',
          labelWidth: 130,

          span: 8,
          type: 'switch',
          dataType: "string",
          value: '2',
          dicUrl: `/api/sinoma-system/dict/dictionary?code=yes_no`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          row: true
        },
        {
          label: this.$t('cip.plat.sys.feedback.field.createTime'),
          labelWidth: 130,
          span: 8,
          readonly: true,
          prop: 'createTime',
          align: "center",
          type: 'datetime',
          format: 'yyyy-MM-dd HH:mm',
          width: 165,
        },
        {
          label: this.$t('cip.plat.sys.feedback.field.disposeTime'),
          labelWidth: 130,
          span: 8,
          prop: 'disposeTime',
          align: "center",
          type: 'datetime',
          format: 'yyyy-MM-dd HH:mm',
          valueFormat: "yyyy-MM-dd hh:mm",
          width: 165,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.feedback.field.disposeTime'),
              trigger: "blur"
            },
          ],

        },

        {
          label: this.$t('cip.plat.sys.feedback.field.feedback'),
          labelWidth: 130,
          maxlength: 500,
          showWordLimit: true,
          readonly: true,
          span: 24,
          type: 'textarea',
          prop: 'feedback',
        },
        {
          label: this.$t('cip.plat.sys.feedback.field.opinion'),
          labelWidth: 130,
          maxlength: 500,
          showWordLimit: true,
          span: 24,
          type: 'textarea',
          prop: 'opinion',
        },
        {
          label: this.$t(`cip.plat.sys.feedback.field.screenshot`),
          prop: "screenshot",
          labelWidth: 130,
          span: 8,
          min: 0,
          type: 'upload',
          listType: 'picture-img',
          disabled: true,
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        this.dataForm.handler = this.userInfo.real_name;
      })
    },
    headSave(cancel = false) {
      this.formLoading = true;
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
      this.formLoading = false;
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>
::v-deep .el-textarea__inner {
  height: 70vh;
}
</style>
